<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- <div>
      <b-badge
        style="margin-right: 10px"
        pill
        variant="light-primary"
      >
      Aliff Basic
      </b-badge>
    </div> -->

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <!-- Bookmarks Container -->
      <!-- <bookmarks /> -->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <b-badge
        v-if="$store.state.user.unread_notifications_count"
        style="margin-right: 10px"
        pill
        variant="light-primary"
      >
        {{ $store.state.user.unread_notifications_count }} New
        Notifications</b-badge
      > -->
      <!-- <div class="mr-2" v-if="['agent', 'service'].includes(user_type)">
        <feather-icon
          :badge="22"
          icon="AlertTriangleIcon"
          size="21"
          stroke="red"
          fill="yellow"
          cursor="pointer"
          @click="goToPendingActions"
        />
      </div> -->
      <div class="mr-2" v-if="['franchise'].includes(user_type) && showTargetIcon">
        <feather-icon
          icon="TargetIcon"
          @click="$bvModal.show('revenue-modal')"
          size="21"
          badge=" "
          badge-classes="bg-danger badge-xs"
        />
      </div>
      <div class="mr-2">
        <feather-icon
          :badge="$store.state.user.unread_messages_count"
          icon="MessageSquareIcon"
          size="21"
        />
      </div>
      <!-- <Locale /> -->
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
      <!-- <search-bar /> -->
      <!-- <cart-dropdown /> -->
      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav, BBadge } from "bootstrap-vue";
import Bookmarks from "./components/Bookmarks.vue";
import Locale from "./components/Locale.vue";
import SearchBar from "./components/SearchBar.vue";
import DarkToggler from "./components/DarkToggler.vue";
import CartDropdown from "./components/CartDropdown.vue";
import NotificationDropdown from "./components/NotificationDropdown.vue";
import UserDropdown from "./components/UserDropdown.vue";
import store from "@/store";
import CommonServices from "@/apiServices/CommonServices";

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    BBadge,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
  },
  data() {
    return {
      showTargetIcon: false,
    };
  },
  computed: {
    user_type() {
      if (store.getters["user/getUserDetails"]?.user_type) {
        return store.getters["user/getUserDetails"].user_type;
      } else {
        return null;
      }
    },
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    goToPendingActions() {
      this.$router.push({ name: "My Leads", query: { action: "pending" } });
    },
    async getAnnualTargets() {
      try {
        const res = await CommonServices.getAnnualTarget();
        if (res.data.status) {
          if (res.data.data.annual_target.length == 0) {
            this.showTargetIcon = true;
            return;
          }
        }
      } catch (error) {
        console.error("Error in getAnnualTargets ", error);
      }
    },
    async isFormFilled() {
      try {
        const res = await CommonServices.isTargetFormFilled();
        if (res.data.status) {
          if (res.data.data.length == 0) {
            this.showTargetIcon = true;
            return;
          }
        }
      } catch (error) {
        console.error("Error in isFormFilled ", error);
      }
    },
  },
  beforeMount() {
    this.isFormFilled();
  },
};
</script>
<style>
.badge-xs{
  min-width: 0.5rem !important;
  min-height: 0.5rem !important;
  top: -3px !important;
  right: 0px !important;
}
</style>
